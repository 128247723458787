body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

* {
  font-family: "Roboto", sans-serif;
}

h3,
h2,
h1 {
  font-family: "Montserrat", sans-serif;
}

a {
  text-decoration: none;
  color: black;
  font-weight: 400;
}

a:hover {
  color: #01e8bc;
}
