.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
}

.title {
  font-size: 64px;
}

.list {
  list-style-type: none;
  display: flex;
  justify-content: center;
}

ul {
  padding: 0;
}

@media only screen and (max-width: 600px) {
  .list {
    flex-direction: column;
  }
  .el {
    margin: 5px 20px !important;
  }
}

.el {
  margin: 0 20px;
  cursor: pointer;
}
/* 
a {
  text-decoration: none;
} */

.el a:hover {
  color: #01e8bc;
}

footer {
  border-top: 1px solid #f2f2f2;
  padding: 40px 0px;
  margin-top: 20px;
}
